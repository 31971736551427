@if (AuthService.isAuthenticated()) {
@if (socketMessage$ | async; as Message) {
<div class="message_cont">
  <ng-container>
    <div class="top-left message_cont">
      @if (Message['top-left'].length > 1) {
      <span
        class="close_all_messages"
        (click)="clearMessageCollector('top-left')"
      >CLOSE ALL</span>
      }
      @for (item of Message['top-left']; track item; let i = $index) {
      <div
        class="message_layout"
        [ngClass]="[item.classMsg]"
      >
        <div [innerHtml]="item.messages"></div>
        @if (item.btnClose) {
        <div
          (click)="closeMessage(i, item.position)"
          class="killer"
        >
          X
        </div>
        }
      </div>
      }
    </div>
  </ng-container>
  <ng-container>
    <div class="top-center message_cont">
      @if (Message['top-center'].length > 1) {
      <span
        class="close_all_messages"
        (click)="clearMessageCollector('top-center')"
      >CLOSE ALL</span>
      }
      @for (item of Message['top-center']; track item; let i = $index) {
      <div
        class="message_layout"
        [ngClass]="[item.classMsg]"
      >
        <div>{{ i }}{{ item.messages | json }}</div>
        @if (item.btnClose) {
        <div
          (click)="closeMessage(i, item.position)"
          class="killer"
        >
          X
        </div>
        }
        <!-- (click)='SharedData.closeMessage(i)' -->
      </div>
      }
    </div>
  </ng-container>
  <ng-container>
    <div class="top-right message_cont">
      @if (Message['top-right'].length > 1) {
      <span
        class="close_all_messages"
        (click)="clearMessageCollector('top-right')"
      >CLOSE ALL</span>
      }
      @for (item of Message['top-right']; track item; let i = $index) {
      <div
        class="message_layout"
        [ngClass]="[item.classMsg]"
      >
        <div [innerHtml]="item.messages"></div>
        @if (item.btnClose) {
        <div
          (click)="closeMessage(i, item.position)"
          class="killer"
        >
          X
        </div>
        }
        <!-- (click)='SharedData.closeMessage(i)' -->
      </div>
      }
    </div>
  </ng-container>
  <ng-container>
    <div class="bottom-left message_cont">
      @if (Message['bottom-left'].length > 1) {
      <span
        class="close_all_messages"
        (click)="clearMessageCollector('bottom-left')"
      >CLOSE ALL</span>
      }
      @for (item of Message['bottom-left']; track item; let i = $index) {
      <div
        class="message_layout"
        [ngClass]="[item.classMsg]"
      >
        <div [innerHtml]="item.messages"></div>
        @if (item.btnClose) {
        <div
          (click)="closeMessage(i, item.position)"
          class="killer"
        >
          X
        </div>
        }
        <!-- (click)='SharedData.closeMessage(i)' -->
      </div>
      }
    </div>
  </ng-container>
  <ng-container>
    <div class="bottom-center message_cont">
      @if (Message['bottom-center'].length > 1) {
      <span
        class="close_all_messages"
        (click)="clearMessageCollector('bottom-center')"
      >CLOSE ALL</span>
      }
      @for (item of Message['bottom-center']; track item; let i = $index) {
      <div
        class="message_layout"
        [ngClass]="[item.classMsg]"
      >
        <div [innerHtml]="item.messages"></div>
        @if (item.btnClose) {
        <div
          (click)="closeMessage(i, item.position)"
          class="killer"
        >
          X
        </div>
        }
        <!-- (click)='SharedData.closeMessage(i)' -->
      </div>
      }
    </div>
  </ng-container>
  <ng-container>
    <div class="bottom-right message_cont">
      @if (Message['bottom-right'].length > 1) {
      <span
        class="close_all_messages"
        (click)="clearMessageCollector('bottom-right')"
      >CLOSE ALL</span>
      }
      @for (item of Message['bottom-right']; track item; let i = $index) {
      <div
        class="message_layout"
        [ngClass]="[item.classMsg]"
      >
        <div [innerHtml]="item.messages"></div>
        @if (item.btnClose) {
        <div
          (click)="closeMessage(i, item.position)"
          class="killer"
        >
          X
        </div>
        }
        <!-- (click)='SharedData.closeMessage(i)' -->
      </div>
      }
    </div>
  </ng-container>
</div>
}
}
@if (AuthService.isAuthenticated()) {
@if (socketAlertMessage$ | async; as Message) {
<div class="socketPopUpCont">
  @for (item of Message; track item; let i = $index) {
  @if (
  current_panelName == item.module ||
  item.module == 'all' ||
  !item.module
  ) {
  <div class="popUpCont">
    @if (!item.pop_up_kyc) {
    <div
      class="titleCont title5"
      [ngClass]="item.classMsg"
    >
      {{ item.title }}
      @if (item.btnClose) {
      <div
        (click)="closeAlertMessage(i)"
        class="ikbs-close2 close"
      ></div>
      }
    </div>
    <div class="bodyCont">
      <div [innerHtml]="item.content"></div>
    </div>
    }
    <!-- @if (item.pop_up_kyc) {
    <div class="kyc_upload">
      <div>
        <div [ngClass]="{ 'ikbs-break': !!item.icon, greenDot: !item.icon }"></div>
      </div>
      <div class="titleCont title5">
        {{ item.title }}
        @if (item.btnClose) {
        <div
          (click)="closeAlertMessage(i)"
          class="ikbs-close2 close"
        ></div>
        }
      </div>
    </div>
    <div class="bodyCont">
      <div [innerHtml]="item.content"></div>
    </div>
    } -->
  </div>
  }
  }
</div>
}
}
@if (InternalMessageService.Message$ | async; as Message) {
<div class="main_success">
  <div class="bottom-right message_cont">
    @if (Message.length > 0) {
    <span
      class="close_all_messages"
      (click)="InternalMessageService.clearMessageCollector()"
    >CLOSE ALL</span>
    }
    @for (item of Message; track item; let i = $index) {
    <div
      class="message_layout"
      [ngClass]="{
          message_success: item.type === 'suc',
          message_error: item.type === 'err',
          Cloudflare: item.message.includes('Cloudflare')
        }"
    >
      @if (item.composit) {
      <a
        style="width: 64px; cursor: pointer"
        (click)="showDetails(item)"
      >Details</a>
      }
      <div
        class="message_body"
        [innerHtml]="
            item.message
              .replaceAll('\n', '<br>')
              .replaceAll(' ', '&nbsp;&nbsp;')
          "
      ></div>
      <div
        class="killer"
        (click)="InternalMessageService.closeMessage(i)"
      >X</div>
    </div>
    }
  </div>
</div>
}
@if (composit) {
<div class="composit_cont">
  <div class="composit_header">
    <div class="details title1">Details</div>
    <div
      class="ikbs-close2 close"
      (click)="closeComposit()"
    ></div>
  </div>
  <div class="composit_body">
    <div>
      <div class="smallHeaders">Paysystems</div>
      @for (item of composit?.paysystems; track item; let i = $index) {
      <div class="_row">
        <span>{{ i + 1 }}</span>
        <span>{{ item }}</span>
      </div>
      }
    </div>
    <div>
      <div class="smallHeaders">Paymethods</div>
      @for (item of composit?.paymethods; track item; let i = $index) {
      <div class="_row">
        <span>{{ i + 1 }}</span>
        <span>{{ item }}</span>
      </div>
      }
    </div>
  </div>
</div>
}