import { HTTP_INTERCEPTORS, HttpEvent, HttpInterceptorFn, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { StoreService } from "./store.service";
import { SocketService } from "./socket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY } from "rxjs";
import { InternalMessageService } from "./internalMessage.service";
const TOKEN_KEY: string = "session-Token";
const EXP_TOKEN_KEY: string = "_xpirat";

const TOKEN_HEADER_KEY = "o-auth-key"; // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private Trottle: boolean = false;
  AuthService: AuthService = inject(AuthService);
  SocketService: SocketService = inject(SocketService);
  router: Router = inject(Router);
  ActivatedRoute: ActivatedRoute = inject(ActivatedRoute);
  InternalMessageService: InternalMessageService = inject(InternalMessageService)

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes("api/l")) return next.handle(req)
    const token = localStorage.getItem(TOKEN_KEY);
    if (localStorage.getItem(EXP_TOKEN_KEY) !== null && !this.AuthService.isAuthenticated()) {
      if (!req.url.indexOf("account/login")) {
        console.log("test");
        this.AuthService.setToken(null);
        //@ts-ignore
        document.querySelector("body").style["overflowY"] = "hidden";
        if (this.SocketService.isConnected) {
          this.SocketService.disconnect();
        }
        this.router.navigate(["login"]);
        this.InternalMessageService.errorMessage("Token Expired");
        return EMPTY;
      }
    }
    if (token && req.url.indexOf("account/login") == -1) {
      req = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
    }
    req = req.clone({ headers: req.headers.set("Accept", "application/json") });

    return next.handle(req).pipe(
      catchError((err) => {
        if (err && err.type == "API" && err.status > 400 && err.status < 500) {
        }

        if (err && err.status === 401 && err.error && typeof err.error === "string" && err.error.indexOf("Invalid token") !== -1) {
          this.AuthService.setToken(null);
          // document.querySelector('body').style['overflowY'] = 'hidden'
          if (this.SocketService.isConnected) {
            this.SocketService.disconnect();
          }
          this.router.navigate(["/login"]);
        }

        if (
          err &&
          err.error &&
          typeof err.error === "string" &&
          err.error.indexOf("Access denied") !== -1 &&
          (err.url.indexOf("project/interface") || err.url.indexOf("account/role") || err.url.indexOf("en/menu"))
        ) {
          this.AuthService.setToken(null);
          // document.querySelector('body').style['overflowY'] = 'hidden'
          if (this.SocketService.isConnected) {
            this.SocketService.disconnect();
          }
          this.router.navigate(["/login"]);
        }
        return throwError(err);
      }),
      finalize(() => { })
    );
  }
}

export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
