
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { StoreService } from '../../services/store.service';
import { SharedData } from '../../services/sharedData.service';
import { InternalMessageService } from '@services/internalMessage.service';


@Component({
  selector: 'app-buffer-grid',
  templateUrl: './buffer-grid.component.html',
  styleUrls: ['./buffer-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    GridModule
  ],
  standalone: true
})
export class BufferGridComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dataToRender: Array<any> = [];
  public columns = [
    {
      field: 'index',
      title: 'N',
      width: 40
    },
    {
      field: 'name',
      title: 'Name',
      width: 80

    },
    {
      field: 'action',
      title: 'Action',
      width: 60

    },
  ];

  @ViewChild('grid') grid: any;
  public loadingGridFlag: boolean = true;
  public fart = 'string';
  public gridData: any;
  private bufferSub: any;
  private bufferData: any;
  InternalMessageService: InternalMessageService = inject(InternalMessageService)

  constructor(
    private ApiService: ApiService,
    private StoreService: StoreService,
    private SharedData: SharedData,
    private cdRef: ChangeDetectorRef
  ) { }
  private routeCounter = 0;
  private bufferCounter = 0;

  ngOnInit(): void {
    this.bufferSub = this.StoreService.storeObserv$
      .pipe(
        map((elem) => {
          return elem.interface;
        })
      )
      .subscribe((response) => {
        console.log(response)
        console.log(this.bufferCounter)
        console.log(this.routeCounter)
        if (response.buffer) {
          this.gridData = response.buffer
        }
        this.cdRef.detectChanges()
        this.loadingGridFlag = false;
      });
    // setTimeout(() => {
    //   this.dataStateChange({
    //     interface: this.StoreService.getFromStoreCurrent('interface'),
    //     bufferFilter: this.StoreService.getFromStoreCurrent('bufferFilter'),
    //   });
    //   this.cdRef.detectChanges()
    //   this.loadingGridFlag = false;
    // }, 1);
  }

  dataStateChange(response: { interface: any; bufferFilter: any }) {
    if (response.interface[response.interface.length - 1]?.buffer) {
      this.bufferData = JSON.parse(
        JSON.stringify(response.interface[response.interface.length - 1]?.buffer)
      );
      if (
        response.bufferFilter.length > 0 &&
        response.bufferFilter[response.bufferFilter.length - 1].type == 'Data'
      ) {
        let filter = response.bufferFilter[response.bufferFilter.length - 1];
        this.gridData = this.bufferData.filter((el: any) => el.type === filter.name);
        this.cdRef.detectChanges()

        return;
      }
      if (
        response.bufferFilter.length > 0 &&
        response.bufferFilter[response.bufferFilter.length - 1].type == 'schema'
      ) {
        this.gridData = this.bufferData.filter((el: any) => el.dataInfo == 'schema');
      }
    }
    this.cdRef.detectChanges()
    this.loadingGridFlag = false;

  }

  ngOnDestroy(): void {
    this.bufferSub.unsubscribe();
  }

  ngOnChanges() {
    this.loadingGridFlag = false;
  }

  trashBuffer() {
    this.ApiService.Deletemethod('account/buffer', {}).subscribe((response) => {
      this.ApiService.Getmethod(`project/interface`).subscribe((response) => {
        this.InternalMessageService.successMessage('Removed');
        this.StoreService.addToStore_static("interface", response)
        this.bufferData = [];
        this.gridData = [];
        this.InternalMessageService.successMessage('Removed');
        this.cdRef.detectChanges()
      })

      // this.StoreService.clearCurrentCell('bufferFilter')
    });
  }

  pasteBuffer(rowIndex: any, dataItem: any) {
    let projectScope = this.StoreService.getFromStore_Static('current_project');
    console.log(this.StoreService.getFromStore_Static('panel_name'));


    //@ts-ignore
    if (document.getElementById('envirinment')?.dataset?.env) {
      //@ts-ignore

      dataItem.env = document.getElementById('envirinment').dataset.env;
    }
    if (
      this.StoreService.getFromStore_Static('panel_name') == 'core' ||
      this.StoreService.getFromStore_Static('panel_name') == 'notify'
    ) {
      projectScope.alias = this.StoreService.getFromStore_Static('panel_name');
    }
    let dataToSend = {
      id: dataItem.id,
      scope: projectScope.alias,
      bucketData: dataItem.bucketData,
      env: dataItem.env,
    };
    dataToSend.env ? '' : delete dataToSend.env;
    this.ApiService.Putmethod('account/buffer', dataToSend).subscribe(
      (response) => {
        this.InternalMessageService.successMessage('Data pasted');
        this.SharedData.FormAdminPageRefresh.emit();
      }
    );
  }

  deleteBuffer(rowIndex: any, dataItem: any) {
    this.ApiService.Deletemethod('account/buffer', {
      id: dataItem.id,
    }).subscribe((response) => {
      this.ApiService.Getmethod(`project/interface`).subscribe((response) => {
        this.InternalMessageService.successMessage('Removed');
        this.StoreService.addToStore_static("interface", response)
      })
      this.gridData = this.gridData.filter((el: any) => el.id !== dataItem.id);
    });
  }
}
