import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, importProvidersFrom, isDevMode } from "@angular/core";
import { provideRouter, ViewTransitionsFeature, withViewTransitions } from "@angular/router";
import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import { ApiService } from "@services/api.service";
import { AuthInterceptor } from "@services/auth.interceptor";
import { SharedData } from "@services/sharedData.service";
import { KIconService } from "@services/k-icon.service";
import { SocketService } from "@services/socket.service";
import { AppInitService } from "@worker/modules/app/app-init.service";
import { StoreService } from "@services/store.service";
import { ICON_SETTINGS, IconSettingsService } from "@progress/kendo-angular-icons";
import { AuthGuard } from "@services/auth.guard";
import { PanelAccessConfigService } from "@services/panelAccessConfig.service";
import { Formio, FormioAppConfig } from "@formio/angular";
import { formioConfig } from "@worker/configs/formio";
import CalendarWidget from "@worker/shared/formioCalendar/Calendar.component";
import FormioButton from "@worker/shared/formioButton/Button.component";
import IKBSTinyMce from "@worker/shared/formio-editor/formio-tinymce-editor.component";
import SliderComponent from "@worker/shared/formioSlider/Slider.component";
import { FetchPlugin } from "@worker/plugins/formio-fetchPlugin";
import { catchError, map } from "rxjs";
import "@progress/kendo-angular-intl/locales/en-GB/all";
import { provideServiceWorker } from '@angular/service-worker';
import { GamesPlugin } from "./plugins/formio-GamesPlugin";
import { DialogModule } from "@progress/kendo-angular-dialog";

Formio.use({
    components: {
        datetime: CalendarWidget,
        button: FormioButton,
        IKBSTinyMce: IKBSTinyMce,
        SliderComponent: SliderComponent,
    },
});
Formio.registerPlugin(FetchPlugin, "customfetch");
Formio.namespace = "session-";
Formio.registerPlugin(GamesPlugin, "GamesPlugin");

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withViewTransitions()),
        provideAnimations(),
        SharedData,
        SocketService,
        ApiService,
        AppInitService,
        StoreService,
        AuthGuard,
        KIconService,
        PanelAccessConfigService,
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(DialogModule),
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (AppInitService: AppInitService) => {
                return () => {
                    return AppInitService.getInfo();
                };
            },
            deps: [AppInitService, StoreService],
        },
        { provide: IconSettingsService, useClass: KIconService },
        { provide: ICON_SETTINGS, useValue: { type: "font", themeColor: "dark" } },
        { provide: FormioAppConfig, useValue: formioConfig },
        {
            // Set default locale to bg-BG
            provide: LOCALE_ID,
            useValue: "en-GB",
        },
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
};
